@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import url('./fonts.scss');

body {
  margin: auto;
  max-width: 900px;
  font-size: 14px;
  font-family: "worksans-regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6FCFC;
  color: #0E2F04;
  overflow-x: hidden;
}

input,
textarea,
button {
  font-family: inherit;
}
;