@font-face {
  font-family: 'worksans-light';
  src: url('../fonts/WorkSans-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
  
@font-face {
  font-family: 'worksans-regular';
  src: url('../fonts/WorkSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
  
@font-face {
  font-family: 'worksans-bold';
  src: url('../fonts/WorkSans-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'spectral-regular';
  src: url('../fonts/Spectral-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
  
  