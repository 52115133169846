.tabs-component [role="tablist"] {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 1px;
}

.tabs-component [role="tablist"] button {
  border-color: transparent;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  background-color: #d9d9d9;
  transition: border 0.2s ease-in-out 0s;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.tabs-component [role="tablist"] button:last-child {
  border-right-color: transparent;
}

.tabs-component [role="tablist"] button.active {
  color: #fff;
  background-color: #417630;
  position: relative;
}

.tabs-component [role="tablist"] button.completed {
  flex-direction: column;
  align-items: center;
}

.tabs-component [role="tabpanel"] {
  text-align: left;
  background-color: #ffffff;
}

.tabs-component.vertical {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component.vertical [role="tablist"] {
  flex-direction: column;
}

.tabs-component.vertical [role="tabpanel"] {
  flex: 1;
}

.tabs-component [role="tablist"] button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
}

.tabs-component.vertical [role="tablist"] button.active,
.tabs-component.vertical [role="tablist"] button:focus,
.tabs-component.vertical [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #d9d9d9;
}

@media screen and (max-width: 786px) {
  .tabs-component [role="tablist"] button {
  min-width: 50px;
  font-size: 9px;
  padding: 0.5rem 0.6rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  background-color: #D9D9D9;
  transition: border 0.2s ease-in-out 0s;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}

.tabs-component [role="tablist"]{
  gap: 0px;
}
}
