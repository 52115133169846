#RFS-StepperContainer.renter-stepper {
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 30px;
  margin-bottom: -8px;
}

#RFS-StepperContainer.renter-stepper #RFS-StepButton.active, #RFS-StepButton.completed {
  background-color: #417630;
}

#RFS-StepperContainer.renter-stepper #RFS-StepButton span {
  color: transparent
}

#RFS-StepButton {
  width: 1.5em;
  border: none;
  cursor: pointer;
  height: 1.5em;
  margin: 0px;
  display: flex;
  padding: 0px;
  font-size: 1em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #417630;
}

#RFS-ConnectorContainer {
  top: calc((1.5em - 1px) / 2);
    left: calc((-50% + 1.5em) - 8px);
    right: calc((50% + 1.5em) - 8px);
    position: absolute;
    border-color: #417630;
    border-top-style: solid;
    border-top-width: 1px;

}

#RFS-Connector {
  display: block;
    border-color: #417630;
    border-top-style: solid;
    border-top-width: 0px;
}

.Connector-0-2-15 {
  display: block;
    border-color: #417630;
    border-top-style: solid;
    border-top-width: 1px;
}